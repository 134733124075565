import { useEffect, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { fontsLoadedState } from '@/store/fontsLoadedState'
import FontFaceObserver from 'fontfaceobserver'
import PropTypes from 'prop-types'

const FontFaceLoader = ({ fonts, timeOut }) => {
  const setIsLoaded = useSetRecoilState(fontsLoadedState)

  const handleLoaded = useCallback(() => {
    setIsLoaded(true)
  }, [setIsLoaded])

  useEffect(() => {
    const fontObservers = []
    fonts.forEach((fontFamily) => {
      const observer = new FontFaceObserver(fontFamily)
      fontObservers.push(observer.load(null, timeOut))
    })

    Promise.all(fontObservers).then(
      () => {
        handleLoaded()
      },
      () => {
        console.warn('One or more webfonts failed to load') // eslint-disable-line no-console
        handleLoaded()
      }
    )
  }, [fonts, timeOut, handleLoaded])

  return null
}

FontFaceLoader.defaultProps = {
  fonts: [],
  timeOut: 3000,
}

FontFaceLoader.propTypes = {
  fonts: PropTypes.array,
  timeOut: PropTypes.number,
}

export { FontFaceLoader }
