import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import * as styles from './TickerBar.styles.scss'

const TickerBar = ({ children }) => {
  const [pageIsVisible, setPageIsVisible] = useState(true)
  const [isInitial, setIsInitial] = useState(true)
  const handleInitial = () => setIsInitial(false)
  const handleVisibilityChange = (isVisible) => setPageIsVisible(isVisible)

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      {pageIsVisible && (
        <motion.div
          className={styles.TickerBar}
          initial={{ y: '-100%' }}
          animate={{ y: 0 }}
          transition={{
            duration: 0.5,
            delay: isInitial ? 0.25 : 0,
            ease: 'backOut',
          }}
          onAnimationComplete={handleInitial}
        >
          <div className={styles.inner}>
            <Ticker>
              {() => <div className={styles.part}>{children}</div>}
            </Ticker>
          </div>
        </motion.div>
      )}
    </PageVisibility>
  )
}

TickerBar.defaultProps = {
  children: undefined,
}

TickerBar.propTypes = {
  children: PropTypes.node,
}

export { TickerBar }
