import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { useRecoilValue } from 'recoil'
import { fontsLoadedState } from '@/store/fontsLoadedState'
import { Container } from '@/components/Container'
// import { Social } from '@/components/Social'
import { Loader } from '@/components/Loader'
import { TickerBar } from '@/components/TickerBar'
import { Icon } from '@/components/Icon'
import * as styles from './Hero.styles.scss'
import image from '@/assets/bg.jpg'

const LoadableBg = loadable(() => import('@/components/Bg'), {
  resolveComponent: (components) => components.Bg,
})

const Hero = ({ 
  ticker, 
  // social, 
  titleLines, 
  icons }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isComponentLoaded, setIsComponentLoaded] = useState(false)
  const isFontLoaded = useRecoilValue(fontsLoadedState)

  // handle loading
  LoadableBg.load().then(() => setIsComponentLoaded(true))
  const handleImageLoaded = () => setIsImageLoaded(true)

  const isAllLoaded = isFontLoaded && isImageLoaded && isComponentLoaded

  useEffect(() => {
    const img = new Image()
    img.src = image
    img.onload = handleImageLoaded
  }, [])

  return (
    <div className={styles.Hero}>
      <div className={styles.sizer} />
      {!!ticker.length && isAllLoaded && (
        <div className={styles.ticker}>
          <TickerBar>
            {ticker.map((item) => (
              <React.Fragment key={item.id}> • {item.text}</React.Fragment>
            ))}
          </TickerBar>
        </div>
      )}
      <header className={styles.title}>
        <div className={styles.title__inner}>
          <Container>
            {isAllLoaded && (
              <h1 className={styles.title__heading}>
                {titleLines.map((item) => (
                  <span className={styles.title__line} key={item.id}>
                    {item.text}
                  </span>
                ))}
              </h1>
            )}
          </Container>
        </div>
      </header>
      <div className={styles.icons}>
        {icons.map((item) => {
          return (
            <Icon
              key={item.id}
              title={item.title}
              image={item.image}
              link={item.link}
            />
          )
        })}
      </div>
      <div className={styles.bg}>
        <LoadableBg image={image} />
      </div>
      {/* <footer className={styles.footer}>
        <Social links={social} />
      </footer> */}
      {!isAllLoaded && <Loader />}
    </div>
  )
}

Hero.defaultProps = {
  ticker: [],
  // social: [],
  titleLines: [],
  icons: [],
}

Hero.propTypes = {
  ticker: PropTypes.arrayOf(PropTypes.object),
  // social: PropTypes.arrayOf(PropTypes.object),
  titleLines: PropTypes.arrayOf(PropTypes.object),
  icons: PropTypes.arrayOf(PropTypes.object),
}

export { Hero }
