import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import * as styles from './Icon.styles.scss'

const Icon = ({ title, link, image }) => (
  <Link className={styles.Icon} to={link.url} target={link.target}>
    <img src={image} className={styles.image} alt={title} />
    <span className={styles.title}>{title}</span>
  </Link>
)

Icon.defaultProps = {
  link: {},
}

Icon.propTypes = {
  title: PropTypes.string,
  link: PropTypes.object,
  image: PropTypes.string,
}

export { Icon }
