// extracted by mini-css-extract-plugin
export var Container = "Container-styles--Container--1tf17";
export var gridRowD = "Container-styles--gridRowD--2zDPG";
export var gridRowM = "Container-styles--gridRowM--2j4pq";
export var gridColD1 = "Container-styles--gridColD1--157pb";
export var gridColD2 = "Container-styles--gridColD2--25ETq";
export var gridColD3 = "Container-styles--gridColD3--3afnj";
export var gridColD4 = "Container-styles--gridColD4--3OcfK";
export var gridColD5 = "Container-styles--gridColD5--1PvHz";
export var gridColD6 = "Container-styles--gridColD6--2eBtc";
export var gridColD7 = "Container-styles--gridColD7--Egv2M";
export var gridColD8 = "Container-styles--gridColD8--I6cJG";
export var gridColD9 = "Container-styles--gridColD9--1va8F";
export var gridColD10 = "Container-styles--gridColD10--1qMaT";
export var gridColD11 = "Container-styles--gridColD11--1I0d1";
export var gridColD12 = "Container-styles--gridColD12--H4B3Z";
export var gridColM1 = "Container-styles--gridColM1--2PXbN";
export var gridColM2 = "Container-styles--gridColM2--2nn3D";
export var aniSpin = "Container-styles--ani-spin--2kBPo";