import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Block } from '@/components/Block'
import * as styles from './Text.styles.scss'

const Text = ({ heading, body, cta }) => {
  return (
    <Block className={styles.Text} bgColor="white">
      <Container>
        <div className={styles.grid}>
          <div className={styles.head}>{heading && <h3>{heading}</h3>}</div>
          <div className={styles.body}>
            {body && <RichText>{body}</RichText>}
            {cta && (
              <div className={styles.cta}>
                → <a href={cta.url}>{cta.title}</a>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Block>
  )
}

Text.propTypes = {
  heading: PropTypes.node,
  body: PropTypes.node,
  cta: PropTypes.object,
}

export { Text }
