import React from 'react'
import { Hero } from '@/blocks/Hero'
import { Press } from '@/blocks/Press'
import { Text } from '@/blocks/Text'
import cwIcon from '@/assets/icons/cw-animated-64.gif'
import titlescreamIcon from '@/assets/icons/titlescream.png'
import Layout from '@/layout'

const IndexPage = () => (
  <Layout title="Cameron Askin">
    <Hero
      titleLines={[
        { id: 'title-1', text: 'Cameron' },
        { id: 'title-2', text: 'Askin (NZ 1987):' },
        { id: 'title-3', text: 'Internet Art' },
        { id: 'title-4', text: 'Design+Dev' },
      ]}
      ticker={[
        { id: 'tick-1', text: 'Always under construction' },
        { id: 'tick-2', text: 'すぐに来る' },
        { id: 'tick-3', text: 'under konstruksjon' },
        { id: 'tick-4', text: '正在施工' },
        { id: 'tick-5', text: 'Always under construction' },
        { id: 'tick-6', text: '공사중' },
        { id: 'tick-7', text: 'En construction' },
        { id: 'tick-8', text: 'в разработке' },
      ]}
      icons={[
        {
          id: 'cw-icon',
          title: 'Camerons World',
          link: {
            url: 'http://www.cameronsworld.net/',
            target: '_blank',
          },
          image: cwIcon,
        },
        {
          id: 'titlescream',
          title: 'Title Sceam',
          link: {
            url: 'https://titlescream.cameronaskin.com/',
            target: '_blank',
          },
          image: titlescreamIcon,
        },
      ]}
      // social={[
      //   {
      //     id: 'twitter',
      //     title: 'Twitter',
      //     url: 'https://twitter.com/cameronaskin/',
      //     icon: 'twitter',
      //   },
      //   {
      //     id: 'instagram',
      //     title: 'Instagram',
      //     url: 'https://instagram.com/cameronaskin/',
      //     icon: 'instagram',
      //   },
      //   {
      //     id: 'email',
      //     title: 'Email',
      //     url: 'mailto:info@cameronaskin.info',
      //     icon: 'email',
      //   },
      // ]}
    />
    <Text
      heading="About"
      body="Exploring the intersection of art, technology, and visually-induced seizures, Cameron creates unique web projects from unusual content sources such as archived GeoCities sites, stock photos and clip art."
    />
    <Text
      heading="Stand & Marvel"
      body={
        <>
          <p>
            Cameron is the founder of Stand &amp; Marvel, a web design and
            development studio based in Melbourne, Australia.
          </p>
          <p>For commercial projects, please visit the studio website:</p>
        </>
      }
      cta={{
        url: 'https://standandmarvel.com/',
        title: 'Stand & Marvel',
      }}
    />
    <Press
      heading="Exhibitions"
      items={[
        {
          title: 'A Question of Taste, Pera Museum, Istanbul, Turkey',
          url: 'https://www.peramuseum.org/exhibition/a-question-of-taste/1266',
          dateStart: '2021-02-23',
          dateEnd: '2021-08-08',
        },
        {
          title: 'Internet! A Retrospective, San Francisco, USA',
          url: 'http://theinternet.show/',
          dateStart: '2018-10-22',
          dateEnd: '2018-10-23',
        },
        {
          title: 'Espacio Byte: Excavating Lost Cities, Online',
          url: 'http://www.espaciobyte.org/',
          dateStart: '2018-05-03',
        },
        {
          title:
            'Invisible Cities, Wallach Art Gallery, Columbia University, USA',
          url: 'http://www.columbia.edu/cu/wallach/exhibitions/Invisible%20Cities.html',
          dateStart: '2017-04-18',
          dateEnd: '2017-05-20',
        },
        {
          title: 'CYFEST 10, St Petersburg/Mexico City/New York/Bogata',
          url: 'http://www.espaciobyte.org/',
          dateStart: '2016-10-29',
          dateEnd: '2017-01-01',
        },
      ]}
    />
    <Press
      heading="Press"
      items={[
        {
          title: 'Creative Review: Is Digital Design About to Get Nostalgic',
          url: 'https://www.creativereview.co.uk/is-digital-design-about-to-get-nostalgic/',
          dateStart: '2019-03-07',
        },
        {
          title: 'Patrick Altair: Design and Nostalgia',
          url: 'https://www.patrickaltair.com/essays/design-and-nostalgia',
          dateStart: '2016-12-01',
        },
        {
          title: 'Hover States: Interview with Cameron Askin',
          url: 'http://hoverstat.es/articles/interview-with-cameron-askin',
          dateStart: '2019-10-21',
        },
        {
          title:
            'Fast Company: Revisit Everything Wonderful About Geocities With One Impeccable Website',
          url: 'https://www.fastcodesign.com/3050538/everything-wonderful-about-geocities-in-one-visual-time-capsule',
          dateStart: '2015-08-31',
        },
        {
          title:
            'Vice: Travel Back in Time to the Best and Weirdest GeoCities Sites',
          url: 'https://www.vice.com/en_us/article/vvyykj/camerons-world',
          dateStart: '2015-08-24',
        },
        {
          title:
            "It's Nice That: RIP GeoCities: what the internet looked liked before the internet was cool",
          url: 'http://www.itsnicethat.com/articles/geocities-before-the-internet-was-cool',
          dateStart: '2015-08-24',
        },
        {
          title:
            'AV Club: Gaze deeply into this loving tribute to the heyday of Geocities web design',
          url: 'http://www.avclub.com/article/gaze-deeply-loving-tribute-heyday-geocities-web-de-224280',
          dateStart: '2015-08-24',
        },
        {
          title: "BoingBoing: Cameron's World",
          url: 'http://boingboing.net/2015/08/23/camerons-world.html',
          dateStart: '2015-08-23',
        },
        {
          title: 'Creative Review: A love letter to the internet of old',
          url: 'https://www.creativereview.co.uk/a-love-letter-to-the-internet-of-old/',
          dateStart: '2015-08-21',
        },
        {
          title:
            'VICE Motherboard: Beat Your Eyeballs to Death with This Majestic GeoCities Tribute',
          url: 'http://motherboard.vice.com/en_uk/read/beat-your-eyeballs-to-death-with-this-majestic-geocities-tribute',
          dateStart: '2015-08-20',
        },
        {
          title:
            'Madmoizelle: "Cameron’s World", l’hommage foufou à l’Internet des années 90',
          url: 'http://www.madmoizelle.com/camerons-world-geocities-414747',
          dateStart: '2015-08-20',
        },
        {
          title:
            'Kai-You: インターネットの化石が出土！ 謎のサイトが伝える愛のメッセージとは by コダック川口',
          url: 'http://kai-you.net/article/20040',
          dateStart: '2015-08-19',
        },
        {
          title: 'Hyperallergic: Hundreds of Geocities Images Organized Neatly',
          url: 'https://hyperallergic.com/230415/hundreds-of-geocities-images-organized-neatly/',
          dateStart: '2015-08-19',
        },
        {
          title:
            'The Daily Dot: This nostalgia project is keeping GeoCities alive',
          url: 'https://www.dailydot.com/debug/camerons-world-geocities/',
          dateStart: '2015-08-19',
        },
        {
          title: 'The Next Web: Witness a glorious graveyard of Geocities GIFs',
          url: 'http://thenextweb.com/shareables/2015/08/18/flashing-under-construction-gif/',
          dateStart: '2015-08-18',
        },
        {
          title:
            'Gizmodo: Revive los tiempos de Geocities con esta web cargada de GIFs psicodélicos',
          url: 'http://es.gizmodo.com/revive-los-tiempos-de-geocities-con-esta-web-cargada-de-1724800020',
          dateStart: '2015-08-18',
        },
        {
          title:
            "Splinter News: Cameron's World is a virtual romp through weird, wonderful abandoned GeoCities sites",
          url: 'http://es.gizmodo.com/revive-los-tiempos-de-geocities-con-esta-web-cargada-de-1724800020',
          dateStart: '2015-08-18',
        },
        {
          title:
            "Huffington Post: Think Today's Internet Is Weird? Check Out This Madness From Back In The Day",
          url: 'http://www.huffingtonpost.com/entry/camerons-world-geocities_55e6ffdce4b0c818f619d0b5',
          dateStart: '2015-03-07',
        },
        {
          title: 'Patate Cipolle: Enter Cameron’s World',
          url: 'http://patate-cipolle.com/2015/10/12/cameronsworld/',
          dateStart: '2015-10-12',
        },
        {
          title:
            'Spiegel: Retro-Reiseführer: Internet, du sahst schon mal schlimmer aus',
          url: 'http://www.spiegel.de/netzwelt/web/retro-internet-zeitreise-mit-geocities-oldweb-today-wayback-machine-a-1074521.html',
          dateStart: '2015-10-15',
        },
        {
          title:
            'The Verge: Title Scream retro videogame gallery gives delight to 16-bit obsessives',
          url: 'http://www.spiegel.de/netzwelt/web/retro-internet-zeitreise-mit-geocities-oldweb-today-wayback-machine-a-1074521.html',
          dateStart: '2011-10-05',
        },
      ]}
    />
  </Layout>
)

export default IndexPage
