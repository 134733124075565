// extracted by mini-css-extract-plugin
export var Hero = "Hero-styles--Hero--206EC";
export var sizer = "Hero-styles--sizer--otAFp";
export var title = "Hero-styles--title--Nblzx";
export var title__inner = "Hero-styles--title__inner--2RUCw";
export var title__heading = "Hero-styles--title__heading--3ReUw";
export var title__line = "Hero-styles--title__line--1ryl-";
export var bg = "Hero-styles--bg--2X9bA";
export var bg__img = "Hero-styles--bg__img--iCFPX";
export var footer = "Hero-styles--footer--2Orb_";
export var ticker = "Hero-styles--ticker--jVpgc";
export var icons = "Hero-styles--icons--2gPfK";
export var aniSpin = "Hero-styles--ani-spin--2yAkS";