import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { RecoilRoot } from 'recoil'
import { ResizeService } from '@/services/ResizeService'
import { FontFaceLoader } from '@/components/FontFaceLoader'

import '@/styles/main.scss'

const Layout = ({ title, children }) => {
  return (
    <RecoilRoot>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="en" />
        <title>{title}</title>
      </Helmet>
      <FontFaceLoader fonts={['GT-Cinetype-Light', 'GT-Cinetype-Regular']} />
      <ResizeService>
        <div>
          <main>{children}</main>
        </div>
      </ResizeService>
    </RecoilRoot>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
