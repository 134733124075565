import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import { Container } from '@/components/Container'
import { Link } from '@/components/Link'
import { Block } from '@/components/Block'
import * as styles from './Press.styles.scss'

dayjs.extend(advancedFormat)

const Press = ({ heading, items }) => {
  return (
    <Block bgColor="white">
      <Container>
        <div className={styles.grid}>
          <div className={styles.inner}>
            <h3 className={styles.heading}>{heading}</h3>
            {items.map((item) => {
              let dateOutput = '–'
              const dateEnd = dayjs(item.dateEnd, 'YYYY-MM-DD')
              const dateStart = dayjs(item.dateStart, 'YYYY-MM-DD')

              if (item.dateEnd && item.dateStart) {
                if (dateStart.format('YY') === dateEnd.format('YY')) {
                  if (dateStart.format('MM') === dateEnd.format('MM')) {
                    dateOutput = `${dateStart.format(
                      'MMM Do'
                    )}–${dateEnd.format('Do YYYY')}`
                  } else {
                    dateOutput = `${dateStart.format(
                      'MMM Do'
                    )}–${dateEnd.format('MMM Do YYYY')}`
                  }
                } else {
                  dateOutput = `${dateStart.format(
                    'MMM Do YYYY'
                  )}–${dateEnd.format('MMM Do YYYY')}`
                }
              } else if (item.dateStart) {
                dateOutput = dateStart.format('MMM Do YYYY')
              }
              return (
                <div key={item.title} className={styles.item}>
                  <Link
                    to={item.url}
                    className={styles.item__link}
                    target="_blank"
                  >
                    <span className={styles.item__title}>{item.title}</span>
                    <span className={styles.item__date}>{dateOutput}</span>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </Block>
  )
}

Press.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Press }
